@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap);

body {
font-size:16px;
background-color:white;
font-family:Montserrat, sans-serif;
font-weight:400;
margin:0;
}

* {
box-sizing:border-box;
outline:none;
margin:0;
padding:0;
}

.custom-box {
max-width:700px;
background-color:#fff;
border-radius:10px;
animation:fadeInRight 1s ease;
margin:4px auto;
padding:15px;
}

/* 100% {
opacity:1;
transform:translateX(0%);
} */

.custom-box::before,.custom-box::after {
content:'';
clear:both;
display:table;
}

.home-box h3 {
font-size:18px;
color:#000;
font-weight:500;
margin-bottom:15px;
line-height:25px;
}

.home-box p {
font-size:16px;
margin-bottom:10px;
line-height:22px;
color:#000;
font-weight:400;
}

.home-box p span {
font-weight:500;
}

.home-box .btn {
margin-top:20px;
}

.btn {
background-color:#009688;
color:#fff;
border:none;
border-radius:5px;
font-family:Montserrat, sans-serif;
font-size:16px;
cursor:pointer;
display:inline-block;
padding:15px 45px;
}



.quiz-box .question-number,.quiz-box .question-text,.quiz-box .option-container,.quiz-box .next-question-btn,.quiz-box .answers-indicator {
width:100%;
float:left;
}

.quiz-box .question-number {
font-size:18px;
color:#009688;
font-weight:600;
border-bottom:1px solid #ccc;
padding-bottom:10px;
line-height:25px;
}

.quiz-box .question-text {
font-size:22px;
color:#000;
line-height:28px;
font-weight:400;
margin:0;
padding:20px 0;
}

.quiz-box .question-text img {
max-width:100%;
display:block;
margin-top:15px;
}

.quiz-box .option-container .option {
	background-color: #cccccc;
	padding: 13px 15px;
	font-size: 16px;
	line-height: 22px;
	color: #000000;
	border-radius: 5px;
	margin-bottom: 10px;
	cursor: pointer;
	text-transform: capitalize;
	animation: fadeIn 0.3s ease forwards;
	position: relative;
	overflow: hidden;
}

.quiz-box .option-container .option.already-answered {
pointer-events:none;
}

.quiz-box .option-container .option.correct::before {
content:'';
position:absolute;
left:0;
top:0;
height:100%;
width:100%;
background-color:green;
z-index:-1;
animation:slideInLeft .5s ease forwards;
}

.quiz-box .option-container .option.wrong::before {
content:'';
position:absolute;
left:0;
top:0;
height:100%;
width:100%;
background-color:red;
z-index:-1;
animation:slideInLeft .5s ease forwards;
}

.quiz-box .option-container .option.selected::before {
content:'';
position:absolute;
left:0;
top:0;
height:100%;
width:100%;
background-color:blue;
z-index:-1;
animation:slideInLeft .5s ease forwards;
}


.quiz-box .btn {
margin:15px 0;
}

.quiz-box .answers-indicator {
border-top:1px solid #ccc;
}

.quiz-box .answers-indicator div {
height:40px;
width:40px;
display:inline-block;
background-color:#ccc;
border-radius:50%;
margin-right:3px;
margin-top:15px;
}

.quiz-box .answers-indicator div.correct {
background-color:green;
background-image: url("./images/correct.png");
background-size:50%;
background-repeat:no-repeat;
background-position:center;
}

.quiz-box .answers-indicator div.wrong {
background-color:red;
background-image: url("./images/wrong.png");
background-size:50%;
background-repeat:no-repeat;
background-position:center;
}

.result-box {
text-align:center;
}

.result-box h1 {
font-size:36px;
line-height:42px;
color:#009688;
}

.result-box table {
width:100%;
border-collapse:collapse;
margin:30px 0;
}

.result-box table td {
border:1px solid #ccc;
font-weight:500;
color:#000;
width:50%;
text-align:left;
font-size:18px;
padding:8px 15px;
}

.result-box .btn {
margin-right:20px;
}

.custom-box.hide,.result-box.hide {
display:none;
}

.quiz-box .option-container .option.wrong,.quiz-box .option-container .option.correct {
color:#fff;
background-color:green;
pointer-events:none;
}

.quiz-box .option-container .option.wrong {
color:#fff;
background-color:red;
pointer-events:none;
}

.quiz-box .option-container .option.selected {
color:#fff;
background-color:blue;
pointer-events:none;
}

@mediamax-width 767px{
.result-box .btn {
margin-bottom:15px;
}

body {
padding:15px;
}
}

.modal-card {
	max-width: 700px;
  /* position: fixed; */
  /* top: 10px;
  left: 10px; */
  width:100%;
  height: 100%;
  background: white;
	max-height: calc(100vh - 200px);
	overflow-y: auto;
}

.modal-main {
  /* position:fixed; */
  background: white;
  width: 80%;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
	max-height: calc(100vh - 200px);
	overflow-y: auto;
}
.modal-backdrop {
	z-index: 1;
}



.form-submit-button {
	background: #0066A2;
	color: white;
	border-style: outset;
	border-color: #0066A2;
	height: 50px;
	width: 100%;
	font: bold15px arial,sans-serif;
	text-shadow: none;
	position: relative;
	right: 0;
	bottom: 0;
}

.form-submit-button {
	background: #016ABC;
	color: #fff;
	border: 1px solid #eee;
	border-radius: 20px;
	box-shadow: 5px 5px 5px #eee;
	text-shadow: none;
}

.form-submit-button:hover {
	background: #B9DFFF;
	color: #fff;
	border: 1px solid #eee;
	border-radius: 20px;
	box-shadow: 5px 5px 5px #eee;
	text-shadow: none;
}
