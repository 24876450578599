/* 
body {
  font-family: 'Montserrat', sans-serif;
  text-align: center;
}



p {
  font-size: 1.1em;
  margin-bottom: 10px;
  white-space: pre-wrap;
  word-wrap: break-word;
  padding-bottom: 5px;

}
strong{
  text-align: center;
  padding-top: 30px;
}

li{
  padding-bottom: 5px;
}
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
margin-top: 0;
margin-bottom: .5rem;
font-weight: 500;
line-height: 1.2;
text-align: center;
}



.column {


  display:flex;
  flex-direction: column;
  justify-content: center;
}




.columnC {
  padding: 0 0 1.5rem 1.5rem;
  display:flex;
  flex-direction:column;
    flex-wrap: wrap;
  background-color: white;
  justify-content: center;

}

.spacing{
  padding : 0.75rem
}

.title {
  background-color: #ff4c68;
  text-align: left;
}

#title .container-fluid {
  padding: 3% 10%;
}


.navbar {
  padding: 0 5rem 4.5rem 5rem
}

.navbar .navbar-brand {
  font-family: 'Montserrat';
  font-size: 3rem;
  font-weight: bold;
}

.nav-item {
  padding: 0 18px;
}

.nav-link {
  font-size: 1.2rem;
  font-family: 'Montserrat-Thin'
}


.download_button {
  margin: 5% 3% 5% 0;
}

.title_image {
  width: 20%;
  transform: rotate(25deg);
  position: absolute;
  right: 15%;
}


#features {

  position: relative;
  z-index:1;
}

.feature_box {
  padding: 5%;
}

.feature_small{
    font-size: 1.5rem;
}
.testimonial-image{
  width: 10%;
  border-radius: 100%;
  margin: 20px;
}

.carousel-item{
  padding: 7% 15%;
}
.test_head{
  font-size: 3rem;
  line-height: 1.5;
}


.easy{
  width: 15%;
  margin: 20px 20px 50px;
     
}


#pricing{
  padding: 100px;
}

.pricing_col{
  padding: 3% 2%;
  justify-content: center;
}

.pricing_header{
  font-size: 3rem;
  line-height: 1.5;
  justify-content: center;
}

@media (max-width: 1028px){

  #title{
    text-align: center;
  }
  .title_image{
    position: static;
    transform: rotate(0);
    width: 50%;
  }
}
.dog_price{
    font-size: 1.5rem;
}

#cta{
  background-color: #ff4c68;
  padding-top: 50px;
}

.section-heading {
    font-size: 3rem;
    line-height: 1.5;
    text-align: center;
}
.card {
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
  border-radius: 10px;
}

.card-image {
  border-top-right-radius: inherit;
  border-top-left-radius: inherit;
  width: 100%;
  overflow: hidden;
}

.landing-image {
  border-top-right-radius: inherit;
  border-top-left-radius: inherit;
  width: 400px;
  height: 400px;
  overflow: hidden;
}

.card-image img {
  width: inherit;
}

.card-content {
  padding: 15px;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
}

.card-content h2 {
  margin-top: 5px;
  margin-bottom: 5px;
}

.card-content p {
  padding-bottom: 10px;
  height: 45px;
}

.color-section{
  background-color: #fff;
}

.navbar {
   margin-bottom: 0px;
   padding: 0px;
}

.grid-container {
  display: grid;
  grid-template-columns: auto auto auto;
  padding: 10px;
  gap: 50px;
}
.grid-item {
  background-color: rgba(255, 255, 255, 0.8);
  border: 1px solid rgba(0, 0, 0, 0.8);
  padding: 20px;
  font-size: 30px;
  text-align: center;
}

.grid-table {
  display: grid;

  gap: 10px;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  justify-items: center;
  padding : 20px;
}

.center-div {
  justify-content: center;
  display: flex;}

  .countdown-container {
    max-width: 900px;
    min-height: 300px;
    margin: 50px auto;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }

  .countdown-values {
    display: flex;
  }

  .big-text {
    font-weight: bold;
    font-size: 6.5rem;
    line-height: 1;
    margin: 10px;
  }

  .countdown-value {
    margin: 10px 20px;
    text-align: center;
  }

  .countdown-value span {
    font-size: 1.3rem;
  }

  .countdown-input {
    padding: 9px;
    font-size: 19px;
    border-width: 0px;
    background-color: #ffffff;
    color: #000000;
    border-style: solid;
    border-radius: 7px;
    box-shadow: 0px 0px 3px rgba(66, 66, 66, 0.53);
    outline: none;
  }

  .countdown-button {
    color: #ffffff;
    background-color: #568ef5;
    font-size: 20px;
    border: 1px solid #2d63c8;
    border-radius: 5px;
    padding: 10px 50px;
    cursor: pointer;
    margin-bottom: 30px;
  }

  .countdown-button:hover {
    color: #ffffff;
    background-color: #809bfd;
  }

  .countdown-input-button {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  .video-js{
  border:1px solid #009688;
  object-fit:cover;}​

  .my-video-video-js {
  object-fit: cover;}


.a{
  padding-bottom: 50px;
} */


body {
  font-family: 'Montserrat', sans-serif;
  text-align: center;
}

/* h1, h2, h3, h4, h5, h6 {
  font-family: 'Montserrat-bold', sans-serif;
  text-align: center;
} */

p {
  font-size: 1.1em;
  margin-bottom: 10px;
  white-space: pre-wrap;
  word-wrap: break-word;
  padding-bottom: 5px;

}
strong{
  text-align: center;
  padding-top: 30px;
}

li{
  padding-bottom: 5px;
}
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
margin-top: 0;
margin-bottom: .5rem;
font-weight: 500;
line-height: 1.2;
text-align: center;
}



.column {
  /* padding: 0 5rem 4.5rem 5rem; */
  display:flex;
  flex-direction: column;
  justify-content: center;
}




.columnC {
  padding: 0 0 1.5rem 1.5rem;
  display:flex;
  flex-direction:column;
    flex-wrap: wrap;
  background-color: white;
  justify-content: center;
    /* margin-top: calc(var(--bs-gutter-y) * -1);
    margin-right: calc(var(--bs-gutter-x) * -.5);
    margin-left: calc(var(--bs-gutter-x) * -.5); */

}

.spacing{
  padding : 0.75rem
}

.title {
  background-color: #ff4c68;
  text-align: left;
}

#title .container-fluid {
  padding: 3% 10%;
}


.navbar {
  padding: 0 5rem 4.5rem 5rem
}
/* Sections */


/* .white-section{
  background-color:#fff;
} */
/* Navigation Bar */
.navbar .navbar-brand {
  font-family: 'Montserrat';
  font-size: 3rem;
  font-weight: bold;
}

.nav-item {
  padding: 0 18px;
}

.nav-link {
  font-size: 1.2rem;
  font-family: 'Montserrat-Thin'
}

/* Download buttons */
.download_button {
  margin: 5% 3% 5% 0;
}

/* Title Image */
.title_image {
  width: 20%;
  transform: rotate(25deg);
  position: absolute;
  right: 15%;
}

/* Features */
#features {
  /* padding: 7% 15%; */
  position: relative;
  z-index:1;
}

.feature_box {
  padding: 5%;
}

.feature_small{
    font-size: 1.5rem;
}
/* Testimonials */
.testimonial-image{
  width: 10%;
  border-radius: 100%;
  margin: 20px;
}

.carousel-item{
  padding: 7% 15%;
}
.test_head{
  font-size: 3rem;
  line-height: 1.5;
}
/* Press */

.easy{
  width: 15%;
  margin: 20px 20px 50px;
      /* top left&right bottom */
}

/* pricing section */
#pricing{
  padding: 100px;
}

#pricing-black{
  background-color: black;
  color: white;
  padding: 50px;
  display: inline-block;
}
#pricing-black.black-section{
  background-color: black;
  color: white;
  padding: 100px;
  display: inline-block;
}

#black-section{
  background-color: black;
  color: white;
  padding: 100px;
  display: inline-block;
}

.black-section{
  background-color: black;
  color: white;
  padding: 100px;
  display: inline-block;
}

#pricing-black img {
    width: 310px;
    height: 310px;
    max-width: 100%;
    max-height: 100%;
}

#pricing-black p {
    /* margin-left: 40%; */
    /* margin-top: -30%; */
    text-align: left;
    font-family: "Montserrat", sans-serif;
    font-weight: 100;
    line-height: 1.5;
}

.list {
     list-style:disc outside none;
     display:list-item;
     text-align: left;
     }

.pricing_col{
  padding: 3% 2%;
  justify-content: center;
}

.pricing_header{
  font-size: 3rem;
  line-height: 1.5;
  justify-content: center;
}

@media (max-width: 1028px){

  #title{
    text-align: center;
  }
  .title_image{
    position: static;
    transform: rotate(0);
    width: 50%;
  }
}
.dog_price{
    font-size: 1.5rem;
}

#cta{
  background-color: #ff4c68;
  padding-top: 50px;
}

.section-heading {
    font-size: 3rem;
    line-height: 1.5;
    text-align: center;
}
@media (max-width: 768px) {
  .section-heading {
    font-size: 24px;
  }
}
.card {
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
  border-radius: 10px;
}

.card-image {
  border-top-right-radius: inherit;
  border-top-left-radius: inherit;
  width: 100%;
  overflow: hidden;
}

.card .front, .card .back{
    padding: 1rem;
    backface-visibility: hidden;
}

.landing-image {
  border-top-right-radius: inherit;
  border-top-left-radius: inherit;
  width: 400px;
  height: 400px;
  overflow: hidden;
}

.card-image img {
  width: inherit;
}

.card-content {
  padding: 15px;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
}

.card-content h2 {
  margin-top: 5px;
  margin-bottom: 5px;
}

.card-content p {
  padding-bottom: 10px;
  height: 45px;
}

.color-section{
  background-color: #fff;
}

.navbar {
   margin-bottom: 0px;
   padding: 0px;
}

.grid-container {
  display: grid;
  grid-template-columns: auto auto auto;
  padding: 10px;
  gap: 50px;
}
.grid-item {
  background-color: rgba(255, 255, 255, 0.8);
  border: 1px solid rgba(0, 0, 0, 0.8);
  padding: 20px;
  font-size: 30px;
  text-align: center;
}

.grid-table {
  display: grid;
  /* grid-template-columns: 1fr 1fr 1fr; */
  gap: 10px;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  justify-items: center;
  padding : 20px;
}

.center-div {
  justify-content: center;
  display: flex;}

  .countdown-container {
    max-width: 900px;
    min-height: 300px;
    margin: 50px auto;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }

  .countdown-values {
    display: flex;
  }

  .big-text {
    font-weight: bold;
    font-size: 6.5rem;
    line-height: 1;
    margin: 10px;
  }

  .countdown-value {
    margin: 10px 20px;
    text-align: center;
  }

  .countdown-value span {
    font-size: 1.3rem;
  }

  .countdown-input {
    padding: 9px;
    font-size: 19px;
    border-width: 0px;
    background-color: #ffffff;
    color: #000000;
    border-style: solid;
    border-radius: 7px;
    box-shadow: 0px 0px 3px rgba(66, 66, 66, 0.53);
    outline: none;
  }

  .countdown-button {
    color: #ffffff;
    background-color: #568ef5;
    font-size: 20px;
    border: 1px solid #2d63c8;
    border-radius: 5px;
    padding: 10px 50px;
    cursor: pointer;
    margin-bottom: 30px;
  }

  .countdown-button:hover {
    color: #ffffff;
    background-color: #809bfd;
  }

  .countdown-input-button {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  .video-js{
  border:1px solid #009688;
  object-fit:cover;}​

  .my-video-video-js {
  object-fit: cover;}


.a{
  padding-bottom: 50px;
}
