.pdf-container {
  display: flex;
  justify-content: center;
  user-select: none;
  width: 100%;
  height:150%;
}

.controls {
  margin: 20px;
}

@media print {
  .pdf-container {
    display: none;
  }
}

/* @media screen and (max-width: 992px) {
  body {
    background-color: blue;
  }
} */
.react-pdf__Page__canvas {
    margin: 0 auto;
    width: 80% !important;
    height: 100% !important;
}
